<template>
  <AppCollapse class="mb-50">
    <AppCollapseItem
      title
      :is-visible="true"
      class-body="p-0"
      class-header="p-0"
    >
      <template #header>
        <h4 class="card-title font-weight-bolder py-50">
          {{ $t('filters') }}
        </h4>
      </template>

      <div class="p-0">
        <b-row
          align-v="center"
          align-h="start"
        >
          <!-- ANCHOR START DATE -->
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              class="mb-0"
              label-class="h5 py-0"
              :label="$t('invoice.filters.from')"
            >
              <flat-pickr
                :value="startDate"
                class="form-control"
                :placeholder="`${$t('topup.placeholderSelectDate')} DD/MM/YYYY`"
                :config="{
                  allowInput: true,
                  dateFormat: 'Y-m-d',
                  altFormat: 'd/m/Y',
                  altInput: true,
                  maxDate: endDate ? endDate : today,
                  locale: this.$i18n.locale === 'vi' ? Vietnamese : null,
                  disableMobile: true,
                }"
                @input="val => $emit('update:startDate', val)"
              />
            </b-form-group>
          </b-col>

          <!-- ANCHOR END DATE -->
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              class="mb-0"
              label-class="h5 py-0"
              :label="$t('invoice.filters.to')"
            >
              <flat-pickr
                :value="endDate"
                class="form-control"
                :placeholder="`${$t('topup.placeholderSelectDate')} DD/MM/YYYY`"
                :config="{
                  allowInput: true,
                  dateFormat: 'Y-m-d',
                  altFormat: 'd/m/Y',
                  altInput: true,
                  minDate: startDate,
                  maxDate: today,
                  locale: this.$i18n.locale === 'vi' ? Vietnamese : null,
                  disableMobile: true,
                }"
                @input="val => $emit('update:endDate', val)"
              />
            </b-form-group>
          </b-col>

          <!-- ANCHOR Loại thông báo -->
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              class="mb-0"
              label-class="h5 py-0"
              :label="$t('invoice.incorrectInvoices.columns.notificationType')"
            >
              <v-select
                :value="notificationType"
                class="w-100 vue-select-class"
                style="font-size: 1rem;"
                :options="INCORRECT_INVOICE_NOTIFICATION_TYPE_OPTIONS"
                label="label"
                clearable
                :placeholder="$t('placeholderSelect')"
                :reduce="val => val.value"
                @input="val => $emit('update:notificationType', val)"
              >
                <template #option="data">
                  <BBadge
                    :variant="data.variant"
                    rounded
                    class="px-75 text-wrap text-left"
                  >
                    {{ $t(`invoice.incorrectInvoices.notificationType.${data.label}`) }}
                  </BBadge>
                </template>

                <template #selected-option="data">
                  <BBadge
                    :variant="data.variant"
                    rounded
                    class="px-75"
                  >
                    {{ $t(`invoice.incorrectInvoices.notificationType.${data.label}`) }}
                  </BBadge>
                </template>
                <template #no-options>
                  {{ $t('noOptions') }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <!-- ANCHOR Trạng thái TBSS -->
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              class="mb-0"
              label-class="h5 py-0"
              :label="$t('invoice.incorrectInvoices.columns.status')"
            >
              <v-select
                :value="notificationStatus"
                class="w-100 vue-select-class"
                style="font-size: 1rem;"
                :options="INCORRECT_INVOICE_NOTIFICATION_STATUS_OPTIONS"
                label="label"
                clearable
                :placeholder="$t('placeholderSelect')"
                :reduce="val => val.value"
                @input="val => $emit('update:notificationStatus', val)"
              >
                <template #option="data">
                  <BBadge
                    :variant="data.variant"
                    pill
                    class="px-75 text-wrap"
                  >
                    {{ $t(`invoice.incorrectInvoices.notificationStatus.${data.label}`) }}
                  </BBadge>
                </template>

                <template #selected-option="data">
                  <BBadge
                    :variant="data.variant"
                    pill
                    class="px-75"
                  >
                    {{ $t(`invoice.incorrectInvoices.notificationStatus.${data.label}`) }}
                  </BBadge>
                </template>
                <template #no-options>
                  {{ $t('noOptions') }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <!-- ANCHOR Trạng thái CQT -->
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              class="mb-0"
              label-class="h5 py-0"
              :label="$t('invoice.incorrectInvoices.columns.statusOfTaxAuthority')"
            >
              <v-select
                :value="statusOfTaxAuthority"
                class="w-100 vue-select-class"
                style="font-size: 1rem;"
                :options="INCORRECT_INVOICE_NOTIFICATION_STATUS_OF_TAX_AUTHORITY_OPTIONS"
                label="label"
                clearable
                :placeholder="$t('placeholderSelect')"
                :reduce="val => val.value"
                @input="val => $emit('update:statusOfTaxAuthority', val)"
              >
                <template #option="data">
                  <BBadge
                    :variant="data.variant"
                    pill
                    class="px-75 text-wrap"
                  >
                    {{ $t(`invoice.incorrectInvoices.statusOfTaxAuthority.${data.label}`) }}
                  </BBadge>
                </template>

                <template #selected-option="data">
                  <BBadge
                    :variant="data.variant"
                    pill
                    class="px-75"
                  >
                    {{ $t(`invoice.incorrectInvoices.statusOfTaxAuthority.${data.label}`) }}
                  </BBadge>
                </template>
                <template #no-options>
                  {{ $t('noOptions') }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <!-- ANCHOR Tìm kiếm theo số thông báo -->
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              class="mb-0"
              label-class="h5 py-0"
              :label="$t('invoice.incorrectInvoices.columns.notificationNumber')"
            >
              <b-form-input
                :value="notificationNumberFilter"
                :placeholder="$t('invoice.incorrectInvoices.ph.notificationNumberSearch')"
                :formatter="trimInput"
                lazy-formatter
                @input="val => $emit('update:notificationNumberFilter', val)"
                @keyup.enter="refetchData"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="auto"
            class="flex-fill"
            align-self="end"
          >
            <div class="d-flex align-items-center justify-content-end gap-1">
              <!-- ANCHOR Button Search -->
              <b-button
                variant="primary"
                size="sm"
                class="p-50"
                @click="refetchData"
              >
                <span class="text-nowrap d-flex-center gap-1">
                  <IAmIcon
                    icon="search"
                    size="16"
                  />
                  {{ $t('search') }}
                </span>
              </b-button>

              <!-- ANCHOR Button Clear Search Filters -->
              <b-button
                variant="outline-danger"
                size="sm"
                class="p-50"
                @click="onClearFilters"
              >
                <span class="text-nowrap d-flex-center gap-1">
                  <IAmIcon
                    icon="delete"
                    size="16"
                  />
                  {{ $t('invoice.create.selectTicket.clearFilter') }}
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
    </AppCollapseItem>
  </AppCollapse>
</template>

<script>
import {
  BRow, BCol, BButton, BFormGroup, BBadge, BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'

import { trimInput } from '@/@core/comp-functions/forms/formatter-input'

import {
  INCORRECT_INVOICE_NOTIFICATION_STATUS_OPTIONS,
  INCORRECT_INVOICE_NOTIFICATION_STATUS_OF_TAX_AUTHORITY_OPTIONS,
  INCORRECT_INVOICE_NOTIFICATION_TYPE_OPTIONS,
} from '../useIncorrectInvoiceHandle'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BBadge,
    BFormInput,
    vSelect,
    flatPickr,
    AppCollapse: () => import('@core/components/app-collapse/AppCollapse.vue'),
    AppCollapseItem: () => import('@core/components/app-collapse/AppCollapseItem.vue'),
  },
  props: {
    startDate: {
      type: String, default: '',
    },
    endDate: {
      type: String, default: '',
    },
    notificationType: {
      type: String, default: '',
    },
    notificationStatus: {
      type: String, default: '',
    },
    statusOfTaxAuthority: {
      type: String, default: '',
    },
    notificationNumberFilter: {
      type: String, default: '',
    },
  },
  setup(props, { emit }) {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())

    const refetchData = () => {
      emit('refetch-data')
    }

    const onClearFilters = () => {
      emit('clear-filters')
    }

    return {
      Vietnamese,
      today,
      onClearFilters,
      refetchData,
      INCORRECT_INVOICE_NOTIFICATION_TYPE_OPTIONS,
      INCORRECT_INVOICE_NOTIFICATION_STATUS_OF_TAX_AUTHORITY_OPTIONS,
      INCORRECT_INVOICE_NOTIFICATION_STATUS_OPTIONS,
      trimInput,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>

<style lang="scss" scoped>
.vue-select-class {
  ::v-deep .vs__dropdown-option--selected {
    color: black !important;
    background-color: #DCDCDC70 !important;
    &.vs__dropdown-option--highlight {
      color: black !important;
      background-color: #DCDCDC70 !important;
    }
  }
}

</style>
