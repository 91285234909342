var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AppCollapse', {
    staticClass: "mb-50"
  }, [_c('AppCollapseItem', {
    attrs: {
      "title": "",
      "is-visible": true,
      "class-body": "p-0",
      "class-header": "p-0"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title font-weight-bolder py-50"
        }, [_vm._v(" " + _vm._s(_vm.$t('filters')) + " ")])];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "p-0"
  }, [_c('b-row', {
    attrs: {
      "align-v": "center",
      "align-h": "start"
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('invoice.filters.from')
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "value": _vm.startDate,
      "placeholder": "".concat(_vm.$t('topup.placeholderSelectDate'), " DD/MM/YYYY"),
      "config": {
        allowInput: true,
        dateFormat: 'Y-m-d',
        altFormat: 'd/m/Y',
        altInput: true,
        maxDate: _vm.endDate ? _vm.endDate : _vm.today,
        locale: this.$i18n.locale === 'vi' ? _vm.Vietnamese : null,
        disableMobile: true
      }
    },
    on: {
      "input": function input(val) {
        return _vm.$emit('update:startDate', val);
      }
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('invoice.filters.to')
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "value": _vm.endDate,
      "placeholder": "".concat(_vm.$t('topup.placeholderSelectDate'), " DD/MM/YYYY"),
      "config": {
        allowInput: true,
        dateFormat: 'Y-m-d',
        altFormat: 'd/m/Y',
        altInput: true,
        minDate: _vm.startDate,
        maxDate: _vm.today,
        locale: this.$i18n.locale === 'vi' ? _vm.Vietnamese : null,
        disableMobile: true
      }
    },
    on: {
      "input": function input(val) {
        return _vm.$emit('update:endDate', val);
      }
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('invoice.incorrectInvoices.columns.notificationType')
    }
  }, [_c('v-select', {
    staticClass: "w-100 vue-select-class",
    staticStyle: {
      "font-size": "1rem"
    },
    attrs: {
      "value": _vm.notificationType,
      "options": _vm.INCORRECT_INVOICE_NOTIFICATION_TYPE_OPTIONS,
      "label": "label",
      "clearable": "",
      "placeholder": _vm.$t('placeholderSelect'),
      "reduce": function reduce(val) {
        return val.value;
      }
    },
    on: {
      "input": function input(val) {
        return _vm.$emit('update:notificationType', val);
      }
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('BBadge', {
          staticClass: "px-75 text-wrap text-left",
          attrs: {
            "variant": data.variant,
            "rounded": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("invoice.incorrectInvoices.notificationType.".concat(data.label))) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('BBadge', {
          staticClass: "px-75",
          attrs: {
            "variant": data.variant,
            "rounded": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("invoice.incorrectInvoices.notificationType.".concat(data.label))) + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('invoice.incorrectInvoices.columns.status')
    }
  }, [_c('v-select', {
    staticClass: "w-100 vue-select-class",
    staticStyle: {
      "font-size": "1rem"
    },
    attrs: {
      "value": _vm.notificationStatus,
      "options": _vm.INCORRECT_INVOICE_NOTIFICATION_STATUS_OPTIONS,
      "label": "label",
      "clearable": "",
      "placeholder": _vm.$t('placeholderSelect'),
      "reduce": function reduce(val) {
        return val.value;
      }
    },
    on: {
      "input": function input(val) {
        return _vm.$emit('update:notificationStatus', val);
      }
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('BBadge', {
          staticClass: "px-75 text-wrap",
          attrs: {
            "variant": data.variant,
            "pill": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("invoice.incorrectInvoices.notificationStatus.".concat(data.label))) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('BBadge', {
          staticClass: "px-75",
          attrs: {
            "variant": data.variant,
            "pill": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("invoice.incorrectInvoices.notificationStatus.".concat(data.label))) + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('invoice.incorrectInvoices.columns.statusOfTaxAuthority')
    }
  }, [_c('v-select', {
    staticClass: "w-100 vue-select-class",
    staticStyle: {
      "font-size": "1rem"
    },
    attrs: {
      "value": _vm.statusOfTaxAuthority,
      "options": _vm.INCORRECT_INVOICE_NOTIFICATION_STATUS_OF_TAX_AUTHORITY_OPTIONS,
      "label": "label",
      "clearable": "",
      "placeholder": _vm.$t('placeholderSelect'),
      "reduce": function reduce(val) {
        return val.value;
      }
    },
    on: {
      "input": function input(val) {
        return _vm.$emit('update:statusOfTaxAuthority', val);
      }
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('BBadge', {
          staticClass: "px-75 text-wrap",
          attrs: {
            "variant": data.variant,
            "pill": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("invoice.incorrectInvoices.statusOfTaxAuthority.".concat(data.label))) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('BBadge', {
          staticClass: "px-75",
          attrs: {
            "variant": data.variant,
            "pill": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("invoice.incorrectInvoices.statusOfTaxAuthority.".concat(data.label))) + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('invoice.incorrectInvoices.columns.notificationNumber')
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": _vm.notificationNumberFilter,
      "placeholder": _vm.$t('invoice.incorrectInvoices.ph.notificationNumberSearch'),
      "formatter": _vm.trimInput,
      "lazy-formatter": ""
    },
    on: {
      "input": function input(val) {
        return _vm.$emit('update:notificationNumberFilter', val);
      },
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.refetchData($event);
      }
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "flex-fill",
    attrs: {
      "cols": "12",
      "md": "auto",
      "align-self": "end"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-end gap-1"
  }, [_c('b-button', {
    staticClass: "p-50",
    attrs: {
      "variant": "primary",
      "size": "sm"
    },
    on: {
      "click": _vm.refetchData
    }
  }, [_c('span', {
    staticClass: "text-nowrap d-flex-center gap-1"
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "search",
      "size": "16"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('search')) + " ")], 1)]), _c('b-button', {
    staticClass: "p-50",
    attrs: {
      "variant": "outline-danger",
      "size": "sm"
    },
    on: {
      "click": _vm.onClearFilters
    }
  }, [_c('span', {
    staticClass: "text-nowrap d-flex-center gap-1"
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "delete",
      "size": "16"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('invoice.create.selectTicket.clearFilter')) + " ")], 1)])], 1)])], 1)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }